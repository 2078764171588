import get from 'lodash/get';
import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { PersonalInfoType } from '@/services/api/type/room.type';
import { CustomerImageOCRType } from '../type/customer.type';

export interface AddCustomerParams {
  partner_id?: string;
  status?: string;
  dob?: string;
  name?: string;
  gender?: string;
  phone?: string;
  email?: string;
  vat?: string;
  identification?: string;
  company_type?: string;
  street?: string;
  state_id?: number;
  country_id?: number;
  comment?: string;
  guest?: {
    guest_id?: number;
    main_guest?: boolean;
    is_child?: boolean;
  };
}

export interface UpdateOrCreateGuestProfile {
  profiles: AddCustomerParams[];
  booking_line_id?: number;
}

export const addCustomer = (params: AddCustomerParams): Promise<PersonalInfoType> => {
  return axiosInstance
    .post(paths.addCustomer(), {
      customer_list: [params]
    })
    .then(item => get(item.data.data, 0));
};

export const addOrUpdateCustomers = (params: AddCustomerParams[]): Promise<PersonalInfoType[]> => {
  return axiosInstance
    .post(paths.addCustomer(), {
      customer_list: params
    })
    .then(item => item.data.data);
};

export const getCustomerById = (customerId: number): Promise<PersonalInfoType> => {
  return axiosInstance.get(paths.getCustomerById(customerId)).then(item => item.data.data);
};

export const getDataCustomerOCR = (params: CustomerImageOCRType): Promise<any> => {
  return axiosInstance.post(paths.getDataCustomerOCR(), params).then(item => item.data.data);
};

export const updateOrCreateBookingLineGuests = async (
  params: UpdateOrCreateGuestProfile
): Promise<PersonalInfoType> => {
  return axiosInstance
    .post(paths.updateOrCreateBookingLineGuests(), { booking_line_guest: params })
    .then(item => item.data.data);
};
