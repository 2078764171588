import _ from 'lodash';
import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  Upload,
  Form,
  UploadProps,
  notification,
  FormInstance,
  Image,
  Input,
  QRCode,
  Card,
  Space,
  Avatar,
  Typography,
  Descriptions
} from 'antd';
import {
  ZoomInOutlined,
  DeleteFilled,
  CloudUploadOutlined,
  UserOutlined,
  FlagOutlined,
  PhoneOutlined,
  CalendarOutlined,
  HomeOutlined,
  ManOutlined
} from '@ant-design/icons';
import { getFullUserProfilePath, getRedirectUrl } from 'routes/constants';
import { logEvent } from 'services/tracking/gaTracking';
import { useGetLatestOcrCustomerInfo } from 'hooks/useGetOcrCustomerInfo';
import { filterTruthyValues, getCurrentDateTime } from 'utils';
import dayjs from 'dayjs';

import { useMutation } from '@tanstack/react-query';

import useBookingStore from 'stores/useBooking';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { assignCustomer } from 'services/api/module/booking.api';
import { addOrUpdateCustomers } from 'services/api/module/customer.api';

export const LIMIT_SIZE_FILE = 2000000; // 2MB
export const LIMIT_PHOTO = 5;

interface Props {
  selectedCustomerId: string | number;
  form: FormInstance;
  isEdit?: boolean;
  onSubmit?: (values: any) => void;
  onFillCustomerDetails?: (info: any) => void;
}
interface CustomerInfo {
  [key: string]: any;
}

function CustomerPhotos({ selectedCustomerId, isEdit, form, onFillCustomerDetails }: Props) {
  const formKey = isEdit ? [selectedCustomerId] : ['customers', selectedCustomerId];

  const attachments = Form.useWatch([...formKey, 'attachments'], form);
  const profile_id = Form.useWatch([...formKey, 'profile_id'], form);

  const uploadRef = useRef<any>();

  const [photoUrls, setPhotoUrls] = useState<string[]>([]);
  const [selectedPhoto, setSelectedPhoto] = useState<number | undefined>();
  const [isShowPreview, setIsShowPreview] = useState(false);

  const { bookingLineId, bookingLineDetail } = useBookingStore();

  const [date, setDate] = useState<string>();
  const { data: latestOcrCustomerInfo } = useGetLatestOcrCustomerInfo(date);

  useEffect(() => {
    setDate(getCurrentDateTime());
  }, []);

  const updatedCustomerInfo: Partial<CustomerInfo> = latestOcrCustomerInfo
    ? filterTruthyValues(latestOcrCustomerInfo)
    : {};

  const { mutateAsync: assignImageCustomer } = useMutation({
    mutationFn: async (values: any) => {
      const image_binary = Array.isArray(values.image_binary)
        ? values.image_binary
        : [values.image_binary];
      const data = {
        partner_id: values.partner_id,
        name: values.name,
        images: image_binary
      };
      await addOrUpdateCustomers([data]);

      let updatedCustomers = bookingLineDetail?.customers || [];
      if (profile_id !== values.partner_id) {
        updatedCustomers = updatedCustomers.filter(customers => customers.id !== profile_id);
        updatedCustomers.push({
          id: values.partner_id,
          gender: values.gender,
          name: values.name,
          identification: values.identification,
          country: values.country_id
        });
      }

      return assignCustomer({
        booking_line_id: bookingLineId,
        customer_ids: updatedCustomers.map(item => item.id)
      });
    }
  });

  const handleChangeCustomerDetails = async () => {
    if (updatedCustomerInfo) {
      if (Object.keys(updatedCustomerInfo).length > 0) {
        await assignImageCustomer(updatedCustomerInfo);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, bookingLineId]
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST]
        });
      }
      setDate(getCurrentDateTime());
    }
  };

  const handleFillCustomerDetails = () => {
    if (updatedCustomerInfo && Object.keys(updatedCustomerInfo).length > 0) {
      const formattedInfo = {
        customers: {
          [selectedCustomerId]: {
            name: updatedCustomerInfo.name,
            gender: updatedCustomerInfo.gender,
            phone: updatedCustomerInfo.phone,
            email: updatedCustomerInfo.email,
            identification: updatedCustomerInfo.identification,
            dob: updatedCustomerInfo.dob ? dayjs(updatedCustomerInfo.dob) : undefined,
            street: updatedCustomerInfo.street,
            country_id: updatedCustomerInfo.country_id,
            images: updatedCustomerInfo.image_binary
          }
        }
      };
      onFillCustomerDetails ? onFillCustomerDetails(formattedInfo) : {};
      setDate(getCurrentDateTime());
    }
  };

  useEffect(() => {
    let result = [];

    if (!_.isEmpty(attachments)) {
      result = attachments.map((item: any) => {
        if (item.id) {
          return item.url;
        }
        return URL.createObjectURL(item.originFileObj);
      });
      setPhotoUrls(result);
    } else {
      setSelectedPhoto(0);
      setPhotoUrls([]);
    }
    if (!_.isEmpty(result) && _.isNil(selectedPhoto)) {
      setSelectedPhoto(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments]);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleTriggerUpload = (e: any) => {
    uploadRef.current?.upload?.uploader?.onClick(e);
  };

  const handleShowPreview = () => {
    setIsShowPreview(true);
  };

  const handleDeletePhoto = (e: any, index: number) => {
    e.stopPropagation();
    const newPhotosUrls = [...photoUrls];
    newPhotosUrls.splice(index, 1);

    setPhotoUrls(newPhotosUrls);
    if (_.isEmpty(newPhotosUrls)) {
      setSelectedPhoto(undefined);
    } else {
      setSelectedPhoto(index > 0 ? index - 1 : index);
    }

    const photos = _.cloneDeep(form.getFieldValue([...formKey, 'attachments']));
    const deletedPhoto = photos.splice(index, 1);

    if (deletedPhoto[0]?.id) {
      const removeImages = _.cloneDeep(form.getFieldValue([...formKey, 'remove_images'])) || [];
      removeImages.push(deletedPhoto[0]?.id);
      form.setFieldValue([...formKey, 'remove_images'], removeImages);
    }

    form.setFieldValue([...formKey, 'attachments'], photos);
  };

  const handleSelectPhoto = (index: number) => {
    setSelectedPhoto(index);
  };

  const props: UploadProps = {
    accept: 'image/png, image/jpeg, image/jpg, image/bmp',
    name: 'file',
    multiple: false,
    // action: "https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload",
    // onChange({ file }) {
    //   // @ts-ignore
    //   const url = URL.createObjectURL(file);
    //   setPhotoUrls([...photoUrls, url]);
    //   if (_.isNil(selectedPhoto)) {
    //     setSelectedPhoto(0);
    //   }
    // },
    beforeUpload: file => {
      const isPNG = file.type === 'image/png';
      const isBMP = file.type === 'image/bmp';
      const isJPG = ['image/jpg', 'image/jpeg'].includes(file.type);

      if (!(isPNG || isJPG || isBMP)) {
        notification.error({
          message: `${file.name} không phải định dạng jpg/jpeg/png/bmp`
        });
      }
      logEvent({
        category: 'customer',
        action: 'upload_photo',
        label: 'Upload Photo',
        value: 1
      });
      return false;
    },
    showUploadList: false,
    disabled: attachments?.length >= LIMIT_PHOTO
  };

  return (
    <>
      {!latestOcrCustomerInfo ? (
        <Form.Item
          name={[selectedCustomerId, 'attachments']}
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload.Dragger {...props} ref={uploadRef}>
            <p className="ant-upload-drag-icon m-0">
              <CloudUploadOutlined />
            </p>
            {selectedPhoto !== undefined && (
              <div
                className="uploaded-image"
                style={{
                  backgroundImage: `url(${photoUrls[selectedPhoto]})`
                }}
              >
                {!_.isEmpty(photoUrls) && (
                  <div className="overlay" onClick={e => e.stopPropagation()}>
                    <Button
                      icon={<ZoomInOutlined />}
                      className="btn-action"
                      onClick={handleShowPreview}
                    />
                    {attachments?.length < LIMIT_PHOTO && (
                      <Button
                        icon={<CloudUploadOutlined />}
                        className="btn-action"
                        onClick={handleTriggerUpload}
                      />
                    )}
                    <Button
                      icon={<DeleteFilled />}
                      className="btn-action"
                      onClick={e => handleDeletePhoto(e, selectedPhoto)}
                    />
                  </div>
                )}
              </div>
            )}
          </Upload.Dragger>
        </Form.Item>
      ) : (
        <Card
          style={{
            maxWidth: 400,
            margin: 'auto',
            borderRadius: 10,
            overflow: 'hidden',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Space direction="vertical" style={{ width: '100%' }}>
            {latestOcrCustomerInfo.existed_user === true && (
              <>
                <Space
                  align="center"
                  style={{ backgroundColor: '#F9A825', padding: 10, color: '#fff' }}
                >
                  <Avatar icon={<UserOutlined />} />
                  <Typography.Text style={{ marginLeft: 'auto' }}>
                    <strong>CCCD hoặc PASSPORT đã tồn tại</strong>
                  </Typography.Text>
                </Space>
              </>
            )}

            <Descriptions column={1} bordered size="small">
              <Descriptions.Item
                label={
                  <span style={{ display: 'inline-block', width: 80 }}>
                    <FlagOutlined /> Họ và tên
                  </span>
                }
              >
                {latestOcrCustomerInfo.name}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <span style={{ display: 'inline-block', width: 80 }}>
                    <UserOutlined /> ID
                  </span>
                }
              >
                {latestOcrCustomerInfo.identification}
              </Descriptions.Item>

              <Descriptions.Item
                label={
                  <span style={{ display: 'inline-block', width: 80 }}>
                    <ManOutlined /> Giới tính
                  </span>
                }
              >
                {latestOcrCustomerInfo.gender}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <span style={{ display: 'inline-block', width: 80 }}>
                    <CalendarOutlined /> Ngày sinh
                  </span>
                }
              >
                {dayjs(latestOcrCustomerInfo.dob).format('DD/MM/YYYY')}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <span style={{ display: 'inline-block', width: 80 }}>
                    <PhoneOutlined /> SĐT
                  </span>
                }
              >
                {latestOcrCustomerInfo.phone}
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  <span style={{ display: 'inline-block', width: 80 }}>
                    <HomeOutlined /> Địa chỉ
                  </span>
                }
              >
                {latestOcrCustomerInfo.street}
              </Descriptions.Item>
            </Descriptions>

            <Space style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              {!latestOcrCustomerInfo.existed_user && (
                <Button type="default" danger onClick={handleFillCustomerDetails}>
                  Điền thông tin
                </Button>
              )}

              {latestOcrCustomerInfo.existed_user && (
                <Button type="primary" onClick={handleChangeCustomerDetails}>
                  Thay thế
                </Button>
              )}
            </Space>
          </Space>
        </Card>
      )}

      {!latestOcrCustomerInfo && (
        <>
          {!_.isEmpty(photoUrls) && (
            <div className="modal-customer-body__upload-preview">
              {photoUrls.map((item, index) => (
                <div className="preview-item" key={index} onClick={() => handleSelectPhoto(index)}>
                  <img src={item} alt="thumbnail" />
                  {selectedPhoto !== index && <div className="overlay" />}
                </div>
              ))}
            </div>
          )}
          <p className="ant-upload-hint m-0">
            Hỗ trợ ảnh png, jpg, jpeg, bmp và dưới 2MB. Tối đa 5 ảnh
          </p>
          {selectedPhoto !== undefined && (
            <Image
              style={{ display: 'none' }}
              preview={{
                visible: isShowPreview,
                onVisibleChange: visible => setIsShowPreview(visible)
              }}
              src={photoUrls[selectedPhoto]}
            />
          )}
          {isEdit && (
            <Form.Item name={[selectedCustomerId, 'remove_images']} style={{ display: 'none' }}>
              <Input />
            </Form.Item>
          )}
        </>
      )}

      {profile_id && !latestOcrCustomerInfo && (
        <div className="qr-code">
          <QRCode value={getRedirectUrl(getFullUserProfilePath(profile_id))} />
          <div className="note">
            <p className="m-0">Lưu ý</p>
            <ul>
              <li>Bước 1: Nhập tên khách</li>
              <li>Bước 2: Bấm lưu</li>
              <li>Bước 3: Quét QR</li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default CustomerPhotos;
